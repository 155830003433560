import React, { useEffect, useState } from "react";
import { client } from "../../client";
import Header from "../Header/Header";
import { SidebarDesktop, SidebarMobile } from "../Sidebar/Sidebar";
import Whoweare from "../WhoWeAre/Whoweare";
import Whatwedo from "../Whatwedo/Whatwedo";
import { Searchbar } from "../Searchbar/Searchbar";
import { FOOTER_MENU_QUERY, HEADER_MENU_QUERY, LOGOS_QUERY } from "../../query";
import BlogAndFooter from "../Blog&Footer/BlogAndFooter";
import Investment from "../Investment/Investment";
import Activity from "../Activity/Activity";
import TradingViewTickerTape from "../Widgets/TradingViewTickerTape";

const Home = () => {
  const [sidebarMobileActive, setSidebarMobileActive] = useState(false);
  const [sidebarDesktopActive, setSidebarDesktopActive] = useState(false);
  const [footerMenus, setFooterMenus] = useState([]);
  const [headerMenus, setHeaderMenus] = useState([]);
  const [headerChildMenu, setHeaderChildMenu] = useState([]);
  const [logos, setLogos] = useState([]);
  const [searchActive, setSearchActive] = useState(false);
  const [error, setError] = useState(null);
  useEffect(() => {
    let isMounted = true;
    async function fetchData() {
      try {
        const [footerMenusResult, headerMenusResult, logosResult] =
          await Promise.all([
            client.fetch(FOOTER_MENU_QUERY),
            client.fetch(HEADER_MENU_QUERY),
            client.fetch(LOGOS_QUERY),
          ]);

        if (isMounted) {
          setFooterMenus(footerMenusResult);
          setHeaderMenus(headerMenusResult);
          setLogos(logosResult);
        }
      } catch (error) {
        console.error("Error fetching menus:", error);
        if (isMounted) {
          setError(error);
        }
      }
    }

    fetchData();
    return () => {
      isMounted = false;
    };
  }, []);
  // console.log(headerMenus);
  return (
    <div className=" relative">
      <TradingViewTickerTape />
      <SidebarMobile
        sidebarMobileActive={sidebarMobileActive}
        setSidebarMobileActive={setSidebarMobileActive}
        headerChildMenu={headerChildMenu}
        headerMenus={headerMenus}
      />
      <Searchbar
        searchActive={searchActive}
        setSearchActive={setSearchActive}
      />
      <SidebarDesktop
        setSidebarDesktopActive={setSidebarDesktopActive}
        sidebarDesktopActive={sidebarDesktopActive}
        headerChildMenu={headerChildMenu}
        setHeaderChildMenu={setHeaderChildMenu}
      />
      <Header
        searchActive={searchActive}
        setSearchActive={setSearchActive}
        sidebarMobileActive={sidebarMobileActive}
        setSidebarMobileActive={setSidebarMobileActive}
        sidebarDesktopActive={sidebarDesktopActive}
        setSidebarDesktopActive={setSidebarDesktopActive}
        logos={logos}
        headerMenus={headerMenus}
        headerChildMenu={headerChildMenu}
        setHeaderChildMenu={setHeaderChildMenu}
      />
      <Whoweare />
      <Whatwedo />
      <Investment />
      <Activity />
      <BlogAndFooter logos={logos} footerMenus={footerMenus} />
    </div>
  );
};

export default Home;
