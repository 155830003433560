// TradingViewTickerTape.js
import React, { useEffect, useState } from "react";

const TradingViewTickerTape = () => {
  const [isNavbarOutOfView, setIsNavbarOutOfView] = useState(false);
  useEffect(() => {
    const navbar = document.getElementById("navbar");
    if (!navbar) return;

    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsNavbarOutOfView(!entry.isIntersecting);
      },
      {
        root: null, // relative to the viewport
        threshold: 0, // callback when 0% of the target is visible
      }
    );

    observer.observe(navbar);

    // Cleanup the observer on unmount
    return () => {
      if (navbar) {
        observer.unobserve(navbar);
      }
    };
  }, []);
  useEffect(() => {
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.src =
      "https://s3.tradingview.com/external-embedding/embed-widget-ticker-tape.js";
    script.async = true;
    script.innerHTML = JSON.stringify({
      symbols: [
        {
          description: "Coralcoastoil Australia ",
          proName: "XETR:AUS",
        },
        {
          description: "Coralcoastoil Australia CME",
          proName: "CME:6A1!",
        },
        {
          description: "Coralcoastoil Australia Bonds",
          proName: "ECONOMICS:AUSP",
        },
        {
          description: "Australia GDP",
          proName: "ECONOMICS:AUGDP",
        },
        {
          description: "FWC of Australia",
          proName: "ECONOMICS:AUMW",
        },
      ],
      showSymbolLogo: true,
      isTransparent: false,
      displayMode: "regular",
      colorTheme: "dark",
      locale: "en",
    });

    document
      .getElementById("tradingview-widget-container__widget")
      .appendChild(script);
  }, []);
  console.log(isNavbarOutOfView);
  return (
    <div
      className={`fixed z-[9999] w-full ${
        isNavbarOutOfView ? "top-0" : "-top-full"
      } transition-all duration-500`}
    >
      {" "}
      <div className="tradingview-widget-container">
        <div id="tradingview-widget-container__widget"></div>
        <div className="tradingview-widget-copyright">
          <a
            href="https://www.tradingview.com/"
            rel="noopener nofollow"
            target="_blank"
          >
            <span className="blue-text"></span>
          </a>
        </div>
      </div>
    </div>
  );
};

export default TradingViewTickerTape;
