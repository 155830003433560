import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
const Charts = ({ serverUsers }) => {
  const [chartData, setChartData] = useState({
    options: {
      chart: {
        id: "partners-activities",
      },
      chart: {
        width: "100%",
        height: "200",
      },
      xaxis: {
        categories: serverUsers,
      },
    },
    series: [
      {
        name: "activities",
        data: [30, 40, 35, 50, 49, 60, 70, 91, 125],
      },
    ],
  });
  useEffect(() => {
    const usernames = serverUsers.map((user) => user.username);
    const amounts = serverUsers.map((user) =>
      parseFloat(user.amount.replace(/[^0-9.-]+/g, ""))
    );

    setChartData({
      options: {
        ...chartData.options,
        xaxis: {
          ...chartData.options.xaxis,
          categories: usernames,
        },
      },
      series: [
        {
          name: "Amount",
          data: amounts,
        },
      ],
    });
  }, [serverUsers]);
  return (
    <span className="w-full  justify-center items-center">
      {" "}
      <Chart
        options={chartData.options}
        series={chartData.series}
        type="line"
        height="150"
      />
    </span>
  );
};

export default Charts;
