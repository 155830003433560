import React, { useRef } from "react";
import Newsroom from "../Newsroom/Newsroom";
import Footer from "../Footer/Footer";
import { useScroll } from "framer-motion";
import Lenis from "lenis";
const BlogAndFooter = ({ logos, footerMenus }) => {
  const container = useRef();
  const { scrollYProgress } = useScroll({
    target: container,
    offset: ["start start", "end end"],
  });
  return (
    <div
      ref={container}
      className="relative mt-40 h-full md:h-[60vh] w-full bg-[#0b2d71] over"
    >
      <Newsroom scrollYProgress={scrollYProgress} />
      <Footer
        logos={logos}
        footerMenus={footerMenus}
        scrollYProgress={scrollYProgress}
      />
    </div>
  );
};

export default BlogAndFooter;
