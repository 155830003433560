import React from "react";

export const reasonsToInvestInOil = [
  {
    title: "Diversification",
    description:
      "Oil investment can diversify your portfolio, reducing risk by balancing your asset allocation.",
  },
  {
    title: "Ownership of Oil Wells",
    description:
      "Investing in oil grants you ownership of an oil well, providing a direct stake in the lucrative energy market alongside other partners. ",
  },
  {
    title: "High Demand",
    description:
      "Oil remains a critical resource globally, ensuring a steady demand which can stabilize invests.",
  },
  {
    title: "Inflation Hedge",
    description:
      "Oil prices often rise in tandem with inflation, which can help protect your investments from the effects of inflationary pressures.",
  },
  {
    title: "Growth Potential",
    description:
      "With the global energy demand expected to grow, oil and gas offers significant growth opportunities.",
  },
  {
    title: "Strong Historical Returns",
    description:
      "Historically, oil and gas  have provided strong returns, contributing to overall portfolio growth.",
  },
  {
    title: "Technological Advancements",
    description:
      "Advances in extraction and production technology can increase efficiency and profitability in the oil sector.",
  },
];
export const carbonImage =
  "https://cdn.sanity.io/images/dl09lxj1/production/6b35fbebf1cfb8665b956bfa44eb86ac74fd073a-768x1024.svg";
export const gridImages = [
  "https://cdn.sanity.io/images/dl09lxj1/production/c1fdf292f48c98c76240fea128db2e9befe0fbf8-463x463.jpg",
  "https://cdn.sanity.io/images/dl09lxj1/production/50ef3fa3ef18219d139bd3f4166e29f67b09c641-822x463.jpg",
  "https://cdn.sanity.io/images/dl09lxj1/production/490bb374847faa02022aa40477889a6986f6ece0-819x463.jpg",
  "https://cdn.sanity.io/images/dl09lxj1/production/21ece529c9d3a9b93a3490a6bdc54a07627ae7b0-694x463.jpg",
  "https://cdn.sanity.io/images/dl09lxj1/production/83be292b9425045ec50ed7ad9f259bea205f6585-822x463.jpg",
  "https://cdn.sanity.io/images/dl09lxj1/production/0bf0511bea553da1dde4687135c58524148f2b38-463x463.jpg",
  "https://cdn.sanity.io/images/dl09lxj1/production/bff8af61bf8cca7213f04589c272889d1fe7ccac-461x463.jpg",
  "https://cdn.sanity.io/images/dl09lxj1/production/12be44decded6ef24298c6f525fe97601d847da5-696x463.jpg",
];

export const tableData = [
  {
    id: 74863,
    firstname: "Ze***k",
    amount: "$11,576.72",
    action: "withdraw",
    username: "Br****e4",
    image:
      "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/975.jpg",
    date: "Sat, May 25",
  },
  {
    id: 25371,
    firstname: "Tu****te",
    amount: "$11,165.08",
    action: "invest",
    username: "Zi***2",
    image:
      "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/1029.jpg",
    date: "Sat, May 25",
  },
  {
    id: 23215,
    firstname: "Le****r",
    amount: "$4,704.28",
    action: "deposit",
    username: "Er***6",
    image:
      "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/730.jpg",
    date: "Sat, May 25",
  },
  {
    id: 63137,
    firstname: "R**b",
    amount: "$6,168.04",
    action: "deposit",
    username: "Bi****79",
    image:
      "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/215.jpg",
    date: "Sat, May 25",
  },
  {
    id: 25830,
    firstname: "Y***t",
    amount: "$4,385.18",
    action: "deposit",
    username: "Z**3",
    image:
      "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/414.jpg",
    date: "Sat, May 25",
  },
  {
    id: 8361,
    firstname: "Jo****n",
    amount: "$18,326.58",
    action: "deposit",
    username: "Ro****1",
    image:
      "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/586.jpg",
    date: "Sat, May 25",
  },
  {
    id: 52546,
    firstname: "Co***y",
    amount: "$10,791.66",
    action: "invest",
    username: "Wi***9",
    image:
      "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/229.jpg",
    date: "Sat, May 25",
  },
  {
    id: 33158,
    firstname: "B***r",
    amount: "$12,182.78",
    action: "deposit",
    username: "Te*****14",
    image:
      "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/321.jpg",
    date: "Sat, May 25",
  },
  {
    id: 6642,
    firstname: "Po*****ki",
    amount: "$12,267.55",
    action: "deposit",
    username: "Le***2",
    image:
      "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/1088.jpg",
    date: "Sat, May 25",
  },
  {
    id: 63309,
    firstname: "Cr***n",
    amount: "$10,677.88",
    action: "withdraw",
    username: "Zi****1",
    image:
      "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/210.jpg",
    date: "Sat, May 25",
  },
];
