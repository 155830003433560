import React, { useEffect, useState } from "react";
// import { headerBg, sliders } from "../../seed/seed";
// import logo from "../../Images/logo.png";
import { IoArrowForwardOutline, IoPause, IoPlay } from "react-icons/io5";
import { client } from "../../client";
import { MoonLoader } from "react-spinners";
import { GET_SLIDERS_QUERY } from "../../query";

const Slider = () => {
  const [pauseSlider, setPauseSlider] = useState(false);
  const [activeSlider, setActiveSlider] = useState(0);
  const [sliders, setSliders] = useState([]);
  function startSliderPlay() {
    setActiveSlider((prevActive) => (prevActive + 1) % sliders.length);
  }

  useEffect(() => {
    if (sliders.length > 0) {
      const playSlider = setInterval(() => {
        if (!pauseSlider) {
          startSliderPlay();
        }
      }, 5000);

      // Cleanup function to clear the interval when component unmounts or when pauseSlider changes
      return () => clearInterval(playSlider);
    }
  }, [pauseSlider, sliders]);

  // You can also add a useEffect to reset the slider when activeSlider reaches the end
  useEffect(() => {
    if (activeSlider === sliders.length) {
      setActiveSlider(0); // Reset to the first slide
    }
  }, [activeSlider, sliders.length]);

  useEffect(() => {
    getSliders();
  }, []);
  async function getSliders() {
    try {
      const data = await client.fetch(GET_SLIDERS_QUERY);

      setSliders(data);
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <div className="mainContent  transition-all ease-in-out duration-1000  w-full flex top-0 relative  md:h-slideDH h-slideMH  justify-center">
      {sliders.length !== 0 ? (
        <ul className="w-full h-full  relative  ">
          {sliders.map((item, index) => (
            <li
              key={item._id}
              style={{
                backgroundImage: item.bgImage
                  ? `url(${item.bgImage.asset.url})`
                  : "none",
              }}
              className={` ${
                activeSlider === index
                  ? `slideContainerSlideIn  ${
                      item.video === false && "zoomSlideContainerBg"
                    }`
                  : ""
              }    ${
                item.bg
              } w-full md:h-slideDH h-slideMH overflow-hidden  absolute top-0 slideContainer  md:p-0 flex justify-center items-center `}
            >
              {item.video && (
                <video
                  className="absolute z-2 videSlider"
                  autoPlay={true}
                  loop={true}
                  controls={false}
                  playsInline
                  muted
                >
                  <source src={item.bgVideo.asset.url} />
                  <img src={item.bgImage} alt={item.linkText} />
                </video>
              )}
              <div
                className={`${
                  activeSlider === index
                    ? "sliderTextContainerSlideIn"
                    : "sliderTextContainerSlideOut"
                } content text-white  ${
                  item.bg
                }  absolute bottom-0 md:bottom-28 w-full md:w-5/12 h-96 rounded-t-2xl py-10 md:rounded-b-xl sliderTextContainer flex justify-start items-center flex-col`}
              >
                <h2 className="flex text-center gothanBlack flex-col text-3xl md:text-6xl font-medium md:font-extrabold justify-start items-center text-white w-full  ">
                  {item.hOne}
                  <span className="gothanBlack">{item.hOneChild}</span>
                </h2>
                <div className="description gothanNarrowBook flex justify-center w-full  text-center items-center mt-6 leading-6 text-sm px-4 md:px-40 md:text-lg">
                  {item.decript}
                </div>
                <a
                  href={"/"}
                  className="flex cursor-pointer justify-center items-center w-full font-bold mt-10 "
                >
                  <IoArrowForwardOutline className="" />

                  <span className="ml-2 gothanBold border-b border-white border-opacity-0 hover:border-opacity-80 py-1">
                    {item.linkText}
                  </span>
                </a>
              </div>
            </li>
          ))}
        </ul>
      ) : (
        <div className="w-full h-full flex justify-center items-center">
          <MoonLoader />
        </div>
      )}

      <div
        className={` ${
          sliders.length !== 0 ? "" : "animate-pulse "
        } absolute bottom-4 md:bottom-10 w-10/12 md:w-3/12 item z-20  h-10 flex `}
      >
        <div
          className={`contolButtons flex h-8  px-4 justify-center items-center opacity-80 w-full  slideControl slideControlBg  rounded-3xl`}
        >
          {sliders.map((item, index) => (
            <button
              key={index}
              onClick={() => setActiveSlider(index)}
              className={`h-1 w-8 md:w-10 transition-all ease-in-out duration-1000  ${
                activeSlider === index ? "progressBarBg" : "bg-gray-400"
              } rounded-2xl mx-2`}
            />
          ))}
        </div>
        <div className={`w-full text-white`}>
          <button
            onClick={() => {
              setPauseSlider(!pauseSlider);
            }}
            className="rounded-full outline-none transition-all ease-linear duration-1000 focus:outline-none  w-8 h-8 mx-8 slideControl slideControlBg flex justify-center items-center hover:animate-pulse"
          >
            {!pauseSlider ? <IoPause /> : <IoPlay />}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Slider;
