import React, { useState } from "react";
import { FaArrowRight } from "react-icons/fa";
import { MdOutlineSearch } from "react-icons/md";
import { toast } from "react-toastify";
import { MoonLoader } from "react-spinners";
import { getRandomDelay } from "../../utils";
export const Searchbar = ({ searchActive, setSearchActive }) => {
  const [activeSearch, setAactiveSearch] = useState(false);
  const handleSearch = () => {
    setAactiveSearch(true);
    setTimeout(() => {
      // toast.error("oops! nothing found");
      setAactiveSearch(false);
      setSearchActive(false);
    }, getRandomDelay());
  };
  return (
    <div
      className={`fixed top-16 h-20  z-[39] bg-white  w-full transition-all duration-500 md:p-4 p-3 flex justify-center items-center ${
        searchActive ? "right-0" : "-right-full"
      }`}
    >
      <div className="border-b border-gray-400 border-opacity-30 w-full h-12 flex justify-center items-center relative">
        {activeSearch && (
          <div className="w-24 h-10  absolute top-0 flex justify-center items-center">
            {" "}
            <MoonLoader size={15} />
          </div>
        )}
        <div className=" text-black w-2/12  flex justify-center items-center flex-col">
          <button>
            <MdOutlineSearch />
          </button>
          <span className=" gothanBold font-bold text-sm ">search</span>
        </div>
        <div className=" w-8/12">
          <input
            className="w-full h-full text-lg md:text-2xl px-2 text-left flex justify-end items-center gothanNarrowBook"
            placeholder="what can we help you find?"
          />
        </div>
        <div
          onClick={handleSearch}
          className=" border-l w-2/12 flex justify-center items-center"
        >
          <button className="text-xl md:text-2xl">
            {" "}
            <FaArrowRight />
          </button>
        </div>
      </div>
    </div>
  );
};
