import React, { useRef, useState, CSSProperties } from "react";
import { Virtual, Navigation, Pagination, Zoom } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { MdOutlineArrowForwardIos } from "react-icons/md";
import { HiOutlineArrowSmRight, HiOutlineArrowSmLeft } from "react-icons/hi";
import { MoonLoader } from "react-spinners";

// import { blogPost } from "../../seed/seed";
import { EffectCards } from "swiper/modules";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/effect-cards";
import "./BlogSlider.css";
import { formatDate } from "../../utils";

const DesktopBlogPostSlider = ({ setActiveIndex, blogPostSanity }) => {
  const [swiperRef, setSwiperRef] = useState(null);

  return (
    <>
      {blogPostSanity.length !== 0 ? (
        <div className="w-full hidden md:flex  absolute right-40 h-full  justify-center items-center">
          {" "}
          <Swiper
            onRealIndexChange={(element) => setActiveIndex(element.activeIndex)}
            modules={[Virtual, Navigation, Pagination]}
            onSwiper={setSwiperRef}
            slidesPerView={blogPostSanity.length}
            centeredSlides={true}
            spaceBetween={30}
            pagination={{
              clickable: true,
            }}
            // navigation={true}
            virtual
          >
            {blogPostSanity.map((post, index) => (
              <SwiperSlide key={post._id} virtualIndex={index}>
                {
                  <div
                    href={post.link}
                    className="w-full h-full flex flex-col transition-none "
                  >
                    <a
                      href={post.link}
                      className="w-full h-[50%]  flex justify-center"
                    >
                      <img
                        className="rounded-[10px] "
                        src={post.mainImage.asset.url}
                        alt={`Blog post${index + 1}`}
                      />
                    </a>
                    <div className="flex transition-none  flex-col w-full justify-start items-start px-6 mt-4">
                      <h3 className="postDate gothanNarrowBook text-sm">
                        {formatDate(post.publishedAt)}
                      </h3>
                      <p className="w-full  text-ellipsis flex justify-start items-start text-left transition-none mt-2 text-lg postTitle gothanNarrowBook">
                        <span> {post.title}</span>
                      </p>
                      <div className="flex justify-center items-center text-xs mt-6 cursor-pointer">
                        <MdOutlineArrowForwardIos />
                        <a
                          href={post.link}
                          className="border-white transition-all px-1 gothanBold duration-500 pb hover:animate-pulse"
                        >
                          {"read article"}
                        </a>
                      </div>
                    </div>
                  </div>
                }
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      ) : (
        <div className="loader w-full md:flex hidden justify-center items-center my-20  h-full">
          <MoonLoader />
        </div>
      )}
    </>
  );
};
const MobileBlogPostSlide = ({ blogPostSanity }) => {
  const [swiperRef, setSwiperRef] = useState(null);
  const swiperRefTrack = useRef();

  return (
    <>
      {" "}
      {blogPostSanity.length !== 0 ? (
        <div className=" w-full flex justify-center items-center md:hidden my-10 px-2   overflow-hidden">
          {/* BLOG SLIDER NEXT AND PREV BUTTONS  */}
          <button
            onClick={() => swiperRefTrack.current?.slidePrev()}
            className="w-7 h-7 ml-2 hover:w-9 hover:h-9 absolute border-2 border-black flex justify-center items-center  transition-all duration-500 hover:bg-black hover:text-white left-0 mt-28 rounded-full"
          >
            <HiOutlineArrowSmLeft />
          </button>
          <button
            onClick={() => swiperRefTrack.current?.slideNext()}
            className="w-7 h-7 mr-2 hover:w-9 hover:h-9  absolute border-2 border-black right-0 mt-28 rounded-full flex transition-all duration-500 justify-center items-center hover:bg-black hover:text-white"
          >
            <HiOutlineArrowSmRight />
          </button>{" "}
          {/* BLOG SLIDER NEXT AND PREV BUTTONS ENDS */}
          <>
            <Swiper
              effect={"cards"}
              grabCursor={true}
              modules={[EffectCards]}
              onBeforeInit={(swiper) => {
                swiperRefTrack.current = swiper;
              }}
              className="mySwiper"
            >
              {blogPostSanity.map((post, index) => (
                <SwiperSlide key={post.title} virtualIndex={index}>
                  {
                    <div className="w-full h-full flex flex-col transition-none ">
                      <div className="w-full h-[45%] flex justify-center item">
                        <img
                          className="rounded-[10px] "
                          src={post.mainImage.asset.url}
                          alt={`Blog post${index + 1}`}
                        />
                      </div>
                      <div className="flex transition-none  flex-col w-full justify-start items-start px-6 mt-4">
                        <h3 className="postDate gothanNarrowBook text-xs">
                          {formatDate(post.publishedAt)}
                        </h3>
                        <p className="w-full text-left transition-none mt-2 text-[14px] postTitle gothanNarrowBook">
                          {post.title}
                        </p>
                        <div className="flex justify-center items-center text-xs mt-6 cursor-pointer">
                          <MdOutlineArrowForwardIos />
                          <a
                            href={post.link}
                            className="border-white transition-all px-1 gothanBold duration-500 pb hover:animate-pulse"
                          >
                            {"read article"}
                          </a>
                        </div>
                      </div>
                    </div>
                  }
                </SwiperSlide>
              ))}
            </Swiper>
          </>
        </div>
      ) : (
        <div className="loader w-full flex md:hidden justify-center items-center my-20">
          <MoonLoader />
        </div>
      )}
    </>
  );
};

export { DesktopBlogPostSlider, MobileBlogPostSlide };
