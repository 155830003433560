import React, { useState } from "react";
// import { menuItemsOne, menuItemsTwo } from "../../seed/seed";
// import logo from "../../Images/logo.png";
import { BsSearch } from "react-icons/bs";
import { TbMenu2 } from "react-icons/tb";
import { RiGlobalLine } from "react-icons/ri";
import { IoArrowForward } from "react-icons/io5";
import { MdOutlineArrowBackIosNew } from "react-icons/md";
import Test from "../../Images/Test";
import BBB from "../../Images/BBB.png";
import Trustpilot from "../../Images/Trustpilot.webp";
const Nav = ({
  sidebarMobileActive,
  setSidebarMobileActive,
  logos,
  sidebarDesktopActive,
  setSidebarDesktopActive,
  headerMenus,
  headerChildMenu,
  setHeaderChildMenu,
  setSearchActive,
  searchActive,
}) => {
  const handleParentMenusMouseOver = (item) => {
    setSidebarDesktopActive(true);
    setHeaderChildMenu(item);
  };

  return (
    <nav
      id="navbar"
      className={`   absolute top-0 ${
        sidebarDesktopActive
          ? "z-[10001] "
          : "z-20  md:bg-backgroundGradientD  md:h-64 "
      } bg-backgroundGradientM text-white transition-all duration-200  px-8 py-2 md:p-2 w-full `}
    >
      <div className="flex md:justify-around justify-between  items-center ">
        {" "}
        <div className="firstMenu flex">
          <TbMenu2
            onClick={() => {
              setSidebarMobileActive(!sidebarMobileActive);
            }}
            className="text-2xl md:hidden cursor-pointer"
          />
          {headerMenus.length !== 0 && (
            <ul className="listOne md:flex justify-center items-center hidden ">
              {headerMenus.map((item, index) => (
                <>
                  {" "}
                  {item.title !== "mini header" && (
                    <li
                      onMouseOver={() => handleParentMenusMouseOver(item)}
                      className=" text-sm mx-4 "
                      key={item.title + index}
                    >
                      <span
                        href="/"
                        className={`border-b transition-all ease-in-out duration-1000 border-white border-opacity-0 hover:border-opacity-80 py-1 ${
                          headerChildMenu.title === item.title &&
                          "border-opacity-80"
                        }`}
                      >
                        {" "}
                        <span className="">{item.title}</span>{" "}
                      </span>
                    </li>
                  )}
                </>
              ))}
            </ul>
          )}
        </div>
        <div className="logo flex justify-center items-center text-white">
          {logos.length ? (
            <img
              className="w-10 md:w-12"
              src={logos[1].logo.asset.url}
              alt="logo"
            />
          ) : (
            <span className="gothanBold">Coralcoastoil</span>
          )}
        </div>
        <div className="secondMenu flex">
          {headerMenus.length !== 0 && (
            <>
              {headerMenus.map(
                (item, index) =>
                  item.title === "mini header" && (
                    <ul
                      key={index}
                      className="listTne md:flex justify-center items-center hidden "
                    >
                      {item.subItems.map((item, index) => (
                        <li className=" text-sm mx-4 " key={item.title + index}>
                          <a
                            href={item.url}
                            className="border-b transition-all ease-in-out duration-1000 border-white border-opacity-0 hover:border-opacity-60 py-1"
                          >
                            {" "}
                            <span className="">{item.title}</span>{" "}
                          </a>
                        </li>
                      ))}
                    </ul>
                  )
              )}
              <button
                onClick={() => setSearchActive(!searchActive)}
                className=" text-2xl cursor-pointer hover:text-blue-400 transition-all duration-300"
              >
                {" "}
                <BsSearch className="" />
              </button>
            </>
          )}
        </div>
      </div>
    </nav>
  );
};

const FooterAboutCompany = ({ logos, footerMenus }) => {
  return (
    <div className="w-full md:w-4/12  flex flex-col justify-center items-center md:justify-start md:items-start mt-4 md:mt-10 ">
      <div className="footerLogo w-full flex justify-center items-center md:justify-start md:items-start ">
        <img alt="footer logo" src={logos[0].logo.asset.url} />
      </div>
      <div className="companyDescript md:mt-32 mt-12 w-full md:w-9/12 md:px-0 px-4 gothanNarrowBook text-center flex justify-center items-center md:text-left md:justify-start md:items-start ">
        <p className="gothanNarrowBook text-[16px] md:text-lg">
          {logos[0].descrip}
        </p>
      </div>
      <div className="companyDescript mt-10 md:mt-32 w-9/12 gothanNarrowBook">
        {footerMenus.map((item, index) => (
          <>
            {item.title === "subscribe to our newsletter" && (
              <ul key={item.title + index}>
                <a
                  href={item.url}
                  key={item.title + index}
                  className="gothanNarrowBook text-lg flex justify-start items-center"
                >
                  <IoArrowForward className=" hover:animate-pulse" />
                  <span className="text-[18px] ml-2 cursor-pointer gothanNarrowBook my-2 pb-[2px] border-b border-b-transparent  hover:border-b-gray-400 transition-all duration-700">
                    {item.title}
                  </span>
                </a>
              </ul>
            )}
          </>
        ))}
      </div>
    </div>
  );
};
const Copyright = ({ footerMenus }) => {
  return (
    <div className="mt-14 flex justify-center items-center w-full md:w-8/12">
      <span className="text-center  md:text-left text-sm md:text-lg">
        {"© 2018 – 2024 Coralcoastoil Corporation. All rights reserved."}
      </span>
    </div>
  );
};
const MiniFooterItems = ({ footerMenus }) => {
  return (
    <div className="footer px-6 md:px-0 justify-center items-center w-full mt-14 flex-col flex text-white">
      <div className="flex flex-col md:flex-row justify-center items-center w-full">
        <div className="flex justify-center items-center full">
          {footerMenus.map((item, index) => (
            <>
              {item.title === "mini footer" && (
                <>
                  {item.subItems && (
                    <ul className="w-full h-full " key={item.title + index}>
                      {item.subItems.sort().map((item, index) => (
                        <a
                          href={item.url}
                          key={item.title + index}
                          className="text-[13px]   gothanNarrowBook cursor-pointer mx-2 my-2 mb-2 md:mx-8 pb-[2px] border-b border-b-transparent  hover:border-b-gray-400 transition-all duration-700"
                        >
                          {item.title}
                        </a>
                      ))}
                    </ul>
                  )}
                </>
              )}
            </>
          ))}
        </div>
        <div className="flex justify-center items-center mt-4 md:mt-0 md:ml-20">
          <RiGlobalLine className="mr-2 text-xl" />
          <span className="gothanBold">Australia | USA | UK</span>
        </div>{" "}
        <div className="flex flex-col md:flex-row justify-center items-center mt-4 md:mt-0 md:ml-20">
          <a href="https://www.bbb.org/us/fl/port-charlotte/profile/construction-services/coral-coast-construction-of-southwest-florida-inc-0653-90211364">
            <img className="md:m-0 my-4 md:mx-1 mx-0" src={BBB} alt="BBB" />
          </a>
          <a className="md:my-0 my-4 md:ml-4 mx-0 w-6/12 md:w-4/12" href="/">
            <img src={Trustpilot} alt="Trustpilot" />
          </a>
        </div>
      </div>
      <Copyright />
    </div>
  );
};
const DesktopFooterItems = ({ footerMenus, logos }) => {
  return (
    <>
      {logos.length !== 0 && (
        <div className="footer w-full px-20 md:flex flex-col justify-center items-start text-white hidden DesktopFooterItems">
          <div className=" w-full px-20 flex justify-center items-start text-white">
            <FooterAboutCompany logos={logos} footerMenus={footerMenus} />
            <div className="w-8/12  ml-24 grid grid-cols-2 gap-4">
              {footerMenus.map((item, index) => (
                <>
                  {item.title !== "mini footer" &&
                    item.title !== "subscribe to our newsletter" && (
                      <div
                        key={item.title + index}
                        className="w-full  my-4 flex flex-col justify-start items-start"
                      >
                        <button className="font-bold text- gothanNarrowBook gothanBold cursor-pointer">
                          {item.title}
                        </button>
                        {item.subItems && (
                          <ul className="my-4 flex flex-col justify- items-start">
                            {item.subItems.sort().map((item, index) => (
                              <a
                                href={item.url}
                                key={item.title + index}
                                className="text-[15px] font-bold cursor-pointer gothanNarrowBook my-1 pb-[2px] border-b border-b-transparent  hover:border-b-gray-400 transition-all duration-700"
                              >
                                {item.title}
                              </a>
                            ))}
                          </ul>
                        )}
                      </div>
                    )}
                </>
              ))}
            </div>
          </div>

          <MiniFooterItems footerMenus={footerMenus} />
        </div>
      )}
    </>
  );
};
const MobileFooterItems = ({ logos, footerMenus }) => {
  const [active, setActive] = useState("");
  const handMenuClick = (menuItem) => {
    if (menuItem === active) {
      setActive("");
    } else {
      setActive(menuItem);
    }
  };
  return (
    <>
      {logos.length !== 0 && footerMenus.length !== 0 && (
        <div className="flex md:hidden flex-col w-full text-white">
          <FooterAboutCompany logos={logos} footerMenus={footerMenus} />
          <div className="w-full px-6">
            {footerMenus.map((item, index) => (
              <>
                {item.title !== "mini footer" &&
                  item.title !== "subscribe to our newsletter" && (
                    <div
                      onClick={() => handMenuClick(item.title)}
                      key={item.title + index}
                      className={`${index === 1 && "border-t"} ${
                        index === footerMenus.length - 1 && "border-b"
                      } border-gray-400 border-opacity-[0.5] w-full border-t my-4 flex flex-col justify-start items-start`}
                    >
                      <div
                        className={` flex justify-between items-center  w-full  `}
                      >
                        {" "}
                        <div className="font-bold text-[14px] my-6 gothanNarrowBook gothanBold cursor-pointer">
                          {item.title}
                        </div>
                        {item.title === active ? (
                          <button
                            onClick={() => handMenuClick(item.title)}
                            className=" cursor-pointer text-white text-xl hover:-rotate-90 transition-all duration-500 rotate-90"
                          >
                            {" "}
                            <MdOutlineArrowBackIosNew className="" />
                          </button>
                        ) : (
                          <button
                            onClick={() => handMenuClick(item.title)}
                            className=" cursor-pointer text-white text-xl hover:rotate-90 transition-all duration-500 -rotate-90"
                          >
                            {" "}
                            <MdOutlineArrowBackIosNew className="" />
                          </button>
                        )}
                      </div>

                      <div className="overflow-hidden w-full">
                        {" "}
                        {item.subItems && (
                          <ul
                            className={`${
                              active === item.title ? "mt-0" : "mb-[-100%]"
                            } my-4 flex flex-[200px_0_0_0] flex-col justify- items-start transition-all duration-500 `}
                          >
                            {item.subItems.sort().map((item, index) => (
                              <a
                                href={item.url}
                                key={item.title + index}
                                className="text-[13px] font-bold cursor-pointer gothanNarrowBook my-2 pb-[2px] border-b border-b-transparent  hover:border-b-gray-400 transition-all duration-300"
                              >
                                {item.title}
                              </a>
                            ))}
                          </ul>
                        )}
                      </div>
                    </div>
                  )}
              </>
            ))}
          </div>
          <MiniFooterItems footerMenus={footerMenus} />
        </div>
      )}
    </>
  );
};
export { Nav, DesktopFooterItems, MobileFooterItems };
