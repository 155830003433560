import React, { useState } from "react";
import { CountdownCircleTimer } from "react-countdown-circle-timer";

const Timer = ({ loadTables, timerDuration }) => {
  return (
    <span className="countdownTimer mt-8 flex flex-col justify-center items-center">
      {" "}
      <CountdownCircleTimer
        isPlaying
        duration={timerDuration}
        size={50}
        strokeWidth={6}
        onComplete={() => {
          loadTables();
          return { shouldRepeat: true, delay: 1.5 }; // repeat animation in 1.5 seconds
        }}
        colors={["#0004777", "#F7B801", "#A30000", "#A30000"]}
        colorsTime={[300, 200, 100, 0]}
      >
        {({ remainingTime }) => remainingTime}
      </CountdownCircleTimer>
      <span
        style={{ fontSize: "10px" }}
        className="flex justify-center items-center mt-2 animate-pulse font-bold"
      >
        Refreshing data
      </span>
    </span>
  );
};

export default Timer;
