import React, { useEffect, useMemo, useState } from "react";
import {
  useReactTable,
  getCoreRowModel,
  flexRender,
  getPaginationRowModel,
} from "@tanstack/react-table";
import { Link } from "react-router-dom";
import Timer from "./Timer";
import { MoonLoader } from "react-spinners";
import { FaMinus, FaPlus, FaChartLine } from "react-icons/fa6";
import { MdShowChart } from "react-icons/md";
import Charts from "../Charts/Chart";
import { tableData } from "../../seed/seed";
const Table = ({ showBtn, loading, loadTables, data, timerDuration }) => {
  const [serverUserID] = useState([]);
  const columns = useMemo(
    () => [
      { header: "ID", accessorKey: "id", footer: "ID" },
      {
        header: "User",
        accessorKey: "username",
        footer: "User",
        cell: (info) => {
          const user = info.getValue();
          const image = info.row.original.image;
          return (
            <div className="flex items-center space-x-2">
              <img
                src={image}
                alt={user}
                className="md:h-10 md:w-10 h-6 w-6 object-cover rounded-full blur-[4px]"
              />

              <span>{user}</span>
            </div>
          );
        },
      },
      { header: "Action", accessorKey: "action", footer: "Action" },
      {
        header: "Amount",
        accessorKey: "amount",
        footer: "Amount",
        cell: (info) => {
          const amount = info.getValue();
          const action = info.row.original.action;
          return (
            <span className="flex items-center space-x-2">
              {action === "withdraw" && (
                <span className="text-red-500">
                  <FaMinus />
                </span>
              )}
              {action === "deposit" && (
                <span className="text-green-500">
                  <FaPlus />
                </span>
              )}
              {action === "invest" && (
                <span className="text-yellow-500">
                  <MdShowChart />
                </span>
              )}
              <span>{amount}</span>
            </span>
          );
        },
      },
      { header: "Date", accessorKey: "date", footer: "Date" },
    ],
    []
  );

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  const getActionClass = (action) => {
    switch (action) {
      case "withdraw":
        return "text-red-600 h-3 text-verySmall md:text-sm rounded-xl py-3 px-2 md:px-4 md:w-7/12";
      case "deposit":
        return "text-green-600 h-3 text-verySmall md:text-sm rounded-xl py-3 px-2 md:px-4 md:w-7/12";
      case "invest":
        return "text-yellow-600 h-3 text-verySmall md:text-sm rounded-xl py-3 px-2 md:px-4 md:w-7/12";
      default:
        return "";
    }
  };
  // console.log(data);
  return (
    <div className="w-full">
      <table className="flex w-full flex-col pt-4 fl-table">
        <caption className="py-4 text border rounded-t-xl">
          <Timer loadTables={loadTables} timerDuration={timerDuration} />
          <Charts serverUsers={data} />
          <span className="text-xs italic">
            Images blured for user's privacy
          </span>
        </caption>

        {!loading ? (
          <>
            <thead className="flex w-full flex-col border text-black">
              {table.getHeaderGroups().map((headerGroup) => (
                <tr className="flex w-full" key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <th
                      className="px-2 py-4 w-full flex overflow-x-scroll justify-start items-center text-sm"
                      key={header.id}
                    >
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody className="flex w-full flex-col border">
              {table.getRowModel().rows.map((row) => (
                <tr className="flex w-full" key={row.id}>
                  {row.getVisibleCells().map((cell) => {
                    const cellValue = cell.getValue();
                    const actionClass =
                      cell.column.columnDef.accessorKey === "action"
                        ? getActionClass(cellValue)
                        : "";
                    return (
                      <td
                        className={`p-2 w-full flex justify-start overflow-x-scroll border items-center text-left`}
                        data-label={cell.column.columnDef.header}
                        key={cell.id}
                      >
                        <span
                          className={`p-2 flex justify-start items-center text-xs text-left ${actionClass}`}
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </span>
                      </td>
                    );
                  })}
                </tr>
              ))}
            </tbody>
            {showBtn === "NAVIGATION" && <TableNav table={table} />}
            {showBtn === "MORE" && <MoreButton />}
          </>
        ) : (
          <div className="loader w-full flex justify-center items-center my-2">
            <MoonLoader />
          </div>
        )}
      </table>
    </div>
  );
};

const MoreButton = () => {
  return (
    <span className="w-full flex justify-center items-center">
      <Link
        to={"/more_activity"}
        className="py-2 cursor-pointer bg-[#0b2d71] border border-[#0b2d71] w-full text-white gothanBlack flex justify-center hover:bg-white hover:text-black transition-all duration-500"
      >
        VIEW MORE
      </Link>
    </span>
  );
};

const TableNav = ({ table }) => {
  return (
    <span className="text-white flex justify-between items-center pt-2">
      <button
        onClick={() => table.setPageIndex(0)}
        className="px-3 text-xs md:text-sm gothanBold text-[#0b2d71] border border-[#0b2d71] rounded-xl hover:bg-black hover:text-white transition-all duration-500"
      >
        First Page
      </button>
      <button
        disabled={!table.getCanPreviousPage()}
        onClick={() => table.previousPage()}
        className="px-3 text-xs md:text-sm gothanBold text-[#0b2d71] border border-[#0b2d71] rounded-xl hover:bg-black hover:text-white transition-all duration-500"
      >
        prev Page
      </button>
      <button
        disabled={!table.getCanNextPage()}
        onClick={() => table.nextPage()}
        className="px-3 text-xs md:text-sm gothanBold text-[#0b2d71] border border-[#0b2d71] rounded-xl hover:bg-black hover:text-white transition-all duration-500"
      >
        next Page
      </button>
      <button
        onClick={() => table.setPageIndex(table.getPageCount() - 1)}
        className="px-3 text-xs md:text-sm gothanBold text-[#0b2d71] border border-[#0b2d71] rounded-xl hover:bg-black hover:text-white transition-all duration-500"
      >
        last Page
      </button>
    </span>
  );
};

export default Table;
