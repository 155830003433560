import React, { useState } from "react";
import { MdCancel, MdOutlineArrowForwardIos } from "react-icons/md";
import { mobileMenuItems } from "../../seed/seed";

const SidebarMobile = ({
  sidebarMobileActive,
  setSidebarMobileActive,
  headerMenus,
}) => {
  const [active, setActive] = useState("");
  const handMenuClick = (menuItem) => {
    if (menuItem === active) {
      setActive("");
    } else {
      setActive(menuItem);
    }
  };
  return (
    <div
      className={`fixed z-40 ${
        sidebarMobileActive ? "left-0" : "-left-full    "
      } h-full w-full bg-[#0b2d71] transition-all duration-200`}
    >
      {headerMenus.length !== 0 && (
        <>
          <div className="cancel w-full flex flex-col justify-end items-end  p-4 transition-all duration-300">
            <MdCancel
              className=" text-white text-3xl hover:text-[#0066b2]"
              onClick={() => {
                setSidebarMobileActive(!sidebarMobileActive);
              }}
            />
          </div>
          <div className="w-full">
            {" "}
            <div className="flex flex-col justify-start ">
              {headerMenus.map((item, index) => (
                <>
                  {item.title !== "mini header" && (
                    <div key={item.title + index} className="flex flex-col ">
                      {" "}
                      <div
                        onClick={() => {
                          handMenuClick(item.title);
                        }}
                        className="py-6 text-xl text-white px-8 "
                      >
                        <a
                          href={item.url}
                          className=" flex justify-between text-[28px] gothanNarrowBook transition-all duration-500  items-center hover:text-[#0066b2]"
                        >
                          <span>{item.title}</span>
                          {item.subItems.length && (
                            <span
                              className={` ${
                                active === item.title
                                  ? "-rotate-90"
                                  : "rotate-90"
                              } `}
                            >
                              <MdOutlineArrowForwardIos />
                            </span>
                          )}
                        </a>
                        <div className="overflow-hidden w-full">
                          {item.subItems && (
                            <ul
                              className={`${
                                active === item.title
                                  ? "mt-0"
                                  : "mb-[-100%] text-white"
                              }  flex flex-[200px_0_0_0] flex-col justify- items-start transition-all duration-500 `}
                            >
                              {item.subItems.sort().map((item, index) => (
                                <a
                                  href={item.url}
                                  key={item.title + index}
                                  className="text-[16px] gothanNarrowBook font-bold cursor-pointer gothanNarrowBook my-2 pb-[2px] border-b border-b-transparent  hover:border-b-gray-400 transition-all duration-300"
                                >
                                  {item.title}
                                </a>
                              ))}
                            </ul>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </>
              ))}
            </div>
            <div className="flex flex-col justify-start ">
              {headerMenus.map((item, index) => (
                <>
                  {item.title === "mini header" && (
                    <>
                      {" "}
                      {item.subItems.map((item, index) => (
                        <div
                          key={item.title + index}
                          className="flex flex-col "
                        >
                          <div className="py-6 text-xl text-white px-8 ">
                            <a
                              href={item.url}
                              className=" flex justify-between text-[25px] gothanNarrowBook  transition-all duration-500  items-center hover:text-[#0066b2]"
                            >
                              <span>{item.title}</span>
                            </a>
                          </div>
                        </div>
                      ))}
                    </>
                  )}
                </>
              ))}
            </div>
          </div>
        </>
      )}
    </div>
  );
};
const SidebarDesktop = ({
  sidebarDesktopActive,
  setSidebarDesktopActive,
  headerChildMenu,
  setHeaderChildMenu,
}) => {
  const handleSideBarClose = () => {
    setSidebarDesktopActive(false);
    setHeaderChildMenu([]);
  };
  return (
    <div
      className={`fixed hidden md:flex z-[10000] ${
        sidebarDesktopActive ? "left-0" : "-left-full"
      } h-full w-6/12 bg-[#0b2d71] transition-all duration-500 flex  justify-center items-start pt-20 `}
    >
      {headerChildMenu.subItems && headerChildMenu.subItems.length !== 0 && (
        <div className="relative  w-full flex  justify-center items-start">
          <ul className={` flex flex-col justify-start overflow-hidden`}>
            {headerChildMenu.subItems.map((item, index) => (
              <li
                key={index}
                className={`py-6 text-4xl cursor-pointer  transition-all duration-500 text-white px-8 hover:text-[#0066b2] `}
              >
                <a
                  href={item.url}
                  className=" flex justify-between items-center gothanNarrowBook"
                >
                  <span>{item.title}</span>
                </a>
              </li>
            ))}
          </ul>
          <button
            onClick={handleSideBarClose}
            className="cancel rounded-full cursor-pointer hover:rotate-45 right-0 h-12 absolute flex justify-end items-center hover:text-6xl text-white text-3xl hover:text-[#b20009] mx-20 mb-60 transition-all duration-200"
          >
            <MdCancel className=" " />
          </button>
        </div>
      )}
    </div>
  );
};
export { SidebarMobile, SidebarDesktop };
