import React from "react";
import { MdOutlineArrowForwardIos } from "react-icons/md";
import { carbonImage } from "../../seed/seed";
const Lowercarbon = () => {
  return (
    <div className="relative h-[150vh] md:h-[110vh] bg-white w-full md:w-8/12  flex justify-center items-center flex-col  z-10">
      <div className="head py-20 z-0 sticky flex top-0 h-screen justify-center mb-20 items-start">
        <h2 className="text-6xl font-bold px-5 leading-lHeightM md:leading-lowerCarbonleading md:px-0 darGreen text-center md:text-lowerCarbon  md:w-8/12   gothanBlack font-">
          lower carbon solutions
        </h2>
      </div>
      <div className=" w-full z-10 relative flex h-screen justify-center items-center mt-16">
        <div className="w-full md:px-28 md:py-24 pt-8 pb-20 px-2 md:w-11/12  bg-[#444b0d]  rounded-t-2xl flex md:flex-row flex-col-reverse justify-between items-center">
          {" "}
          <div className=" md:w-8/12 w-full md:mr-6 flex flex-col justify-start items-start">
            <div className="flex flex-col px-4 w-full ">
              <h3 className="text-6xl md:text-8xl text-[#B2CC34] leading-lHeightM text-left my-2  md:w-10/12 md:leading-lHeightD  gothanBlack ">
                carbon capture and storage
              </h3>
              <p className="text-lg text-white gothanNarrowBook leading-7 md:text-xl md:w-10/12 text-left font-light my-2">
                We’re targeting 25 million tonnes of CO2 per year in offsets
                business and CCUS by the end of this decade. Captured carbon
                emissions are safely stored several thousand feet below ground.
              </p>
            </div>
            <div className="ctaBtn px-4 mt-8 w-full flex justify-start items-start">
              <a
                href="https://secured.coralcoastoil.com/carbon-capture-and-storage/"
                className=" flex justify-evenly  font-bold border-2 border-[#B2CC34] rounded-lg hover:bg-[#B2CC34] transition-all duration-500 px-10 items-center hover:text-black text-white py-3 "
              >
                <span className="mr-4">
                  {" "}
                  <MdOutlineArrowForwardIos />
                </span>

                <span className="gothanBold"> learn about CCS</span>
              </a>
            </div>
          </div>
          <div className="my-10 md:my-0 px-4 md:w-6/12 w-full rounded-xl md:ml-6">
            <img alt="carbonImage" src={carbonImage} className="rounded-xl" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Lowercarbon;
