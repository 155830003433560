import React, { useEffect, useState } from "react";
import Header from "../Header/Header";
import { client } from "../../client";
import {
  FOOTER_MENU_QUERY,
  GET_MORE_TABLE_DATA,
  HEADER_MENU_QUERY,
  LOGOS_QUERY,
} from "../../query";
import Activity from "../Activity/Activity";
import Table from "../Activity/Table";
import BlogAndFooter from "../Blog&Footer/BlogAndFooter";
import { Nav } from "../Navbar/Nav";
import axios from "axios";
import { useLocation } from "react-router-dom";
import ScrollToTop from "../ScrollToTop/ScrollToTop";
import { SidebarDesktop, SidebarMobile } from "../Sidebar/Sidebar";
import { Searchbar } from "../Searchbar/Searchbar";
import TradingViewTickerTape from "../Widgets/TradingViewTickerTape";

const MoreActivity = () => {
  const [sidebarMobileActive, setSidebarMobileActive] = useState(false);
  const [sidebarDesktopActive, setSidebarDesktopActive] = useState(false);
  const [footerMenus, setFooterMenus] = useState([]);
  const [headerMenus, setHeaderMenus] = useState([]);
  const [headerChildMenu, setHeaderChildMenu] = useState([]);
  const [logos, setLogos] = useState([]);
  const [searchActive, setSearchActive] = useState(false);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [dataTable, setDataTable] = useState([]);
  const [timerDuration, setTimerDuration] = useState(0);
  useEffect(() => {
    let isMounted = true;
    async function fetchmanyData() {
      try {
        const [footerMenusResult, headerMenusResult, logosResult] =
          await Promise.all([
            client.fetch(FOOTER_MENU_QUERY),
            client.fetch(HEADER_MENU_QUERY),
            client.fetch(LOGOS_QUERY),
          ]);

        if (isMounted) {
          setFooterMenus(footerMenusResult);
          setHeaderMenus(headerMenusResult);
          setLogos(logosResult);
        }
      } catch (error) {
        console.error("Error fetching menus:", error);
        if (isMounted) {
          setError(error);
        }
      }
    }

    fetchmanyData();
    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    fetchData();
  }, []);
  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await axios.get(GET_MORE_TABLE_DATA);
      // console.log(response.data);
      setDataTable(response.data);
      if (response.data.length) {
        setTimeout(() => {
          setTimerDuration(300);
          setLoading(false);
        }, 2000);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };
  return (
    <div className="">
      {" "}
      <div className=" ">
        <TradingViewTickerTape />
        <ScrollToTop />
        <SidebarMobile
          sidebarMobileActive={sidebarMobileActive}
          setSidebarMobileActive={setSidebarMobileActive}
          headerChildMenu={headerChildMenu}
          headerMenus={headerMenus}
        />
        <Searchbar
          searchActive={searchActive}
          setSearchActive={setSearchActive}
        />
        <Nav
          searchActive={searchActive}
          setSearchActive={setSearchActive}
          sidebarMobileActive={sidebarMobileActive}
          setSidebarMobileActive={setSidebarMobileActive}
          sidebarDesktopActive={sidebarDesktopActive}
          setSidebarDesktopActive={setSidebarDesktopActive}
          logos={logos}
          headerMenus={headerMenus}
          headerChildMenu={headerChildMenu}
          setHeaderChildMenu={setHeaderChildMenu}
        />{" "}
        <SidebarDesktop
          setSidebarDesktopActive={setSidebarDesktopActive}
          sidebarDesktopActive={sidebarDesktopActive}
          headerChildMenu={headerChildMenu}
          setHeaderChildMenu={setHeaderChildMenu}
        />
      </div>{" "}
      <div className="text-black w-full pt-40 flex justify-center items-center flex-col">
        <div className=" flex justify-center items-center">
          <p className="gothanBold  text-2xl md:text-4xl darkBlueTwo">
            Investors Activities
          </p>
        </div>

        <div className="countdownTimer mt-3 flex flex-col justify-center items-center w-11/12 md:w-6/12">
          <Table
            showBtn="NAVIGATION"
            loading={loading}
            loadTables={fetchData}
            data={dataTable}
            timerDuration={timerDuration}
          />
        </div>
      </div>
      <BlogAndFooter logos={logos} footerMenus={footerMenus} />
    </div>
  );
};

export default MoreActivity;
