import React, { useEffect, useRef, useState } from "react";
import { client } from "../../client";
import { GET_BLOG_POST_QUERY } from "../../query";

import {
  DesktopBlogPostSlider,
  MobileBlogPostSlide,
} from "../Slider/BlogSliders";
import "./Newsroom.css";
import { motion, useTransform } from "framer-motion";
const Newsroom = ({ scrollYProgress }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [blogPostSanity, setBlogPostSanity] = useState([]);
  const scale = useTransform(scrollYProgress, [0, 1], [1, 0.8]);
  const rotate = useTransform(scrollYProgress, [0, 1], [0, -5]);

  useEffect(() => {
    getPosts();
  }, []);
  async function getPosts() {
    try {
      const posts = await client.fetch(GET_BLOG_POST_QUERY);

      setBlogPostSanity(posts);
    } catch (error) {
      console.log(error);
    }
  }
  return (
    <motion.section
      style={{ scale, rotate }}
      className="md:h-full h-[50%] bg-white z-10 sticky md:relative top-0  mt-40 md:mt-4 py-6 w-full   justify-evenly items-center   md:px-20"
    >
      <div
        style={{ opacity: activeIndex > 0 ? 0 : 1 }}
        className="md:w-3/12 w-full flex-col md:absolute px-8 md:px-0 text-[#0b2d71] transition-all duration-500  left-[25%]  h-full flex justify-center items-start"
      >
        <h3 className="font-bold text-xl gothanBold w-full flex justify-center md:justify-start">
          newsroom
        </h3>
        <div className="gothanBlack md:w-6/12 mt-4 md:mt-8 w-full flex justify-center md:justify-start ">
          <p className="gothanBlack text-4xl md:text-6xl  flex justify-center md:justify-start  text-center md:text-left">
            latest at coralcoastoil
          </p>
        </div>
      </div>

      {/* SLIDE FOR MOBILE AND DESKTOP */}

      <DesktopBlogPostSlider
        blogPostSanity={blogPostSanity}
        setActiveIndex={setActiveIndex}
      />
      <MobileBlogPostSlide blogPostSanity={blogPostSanity} />
    </motion.section>
  );
};

export default Newsroom;
