import React, { useEffect, useState } from "react";
import Test from "../../Images/Test";
import "./Investment.css";

// import { plans } from "../../seed/seed";
import { GiCheckMark } from "react-icons/gi";
import { AiFillSafetyCertificate } from "react-icons/ai";
import { MoonLoader } from "react-spinners";
import { client } from "../../client";
import {
  GET_INVESTMENTS_QUERY,
  GET_INVESTMENT_SECTION_QUERY,
} from "../../query";
import { reasonsToInvestInOil } from "../../seed/seed";
import { BsShieldLock } from "react-icons/bs";
import { sortPlansWithBestSellerInMiddle } from "../../utils";

const Investment = () => {
  const [plans, setPlans] = useState([]);
  const [plansSection, setPlansSection] = useState({});
  const [error, setError] = useState(null);

  useEffect(() => {
    let isMounted = true;
    async function getPlans() {
      try {
        const [plans, plansSection] = await Promise.all([
          client.fetch(GET_INVESTMENTS_QUERY),
          client.fetch(GET_INVESTMENT_SECTION_QUERY),
        ]);

        if (isMounted) {
          // Sort the plans
          const sortedPlans = sortPlansWithBestSellerInMiddle(plans);
          setPlans(sortedPlans);
          setPlansSection(plansSection[0]);
        }
      } catch (error) {
        console.error("Error fetching menus:", error);
        if (isMounted) {
          setError(error);
        }
      }
    }

    getPlans();
    return () => {
      isMounted = false;
    };
  }, []);
  // console.log(plans);
  return (
    <section className="pt-20 mt-40 md:mt-[200px] relative flex w-full justify-center items-center flex-col darkBlueTwo">
      <div className=" w-full relative h-full z-0 flex flex-col justify-start items-center">
        <div className="whatwedoTextHead h-[50%] top-0  bg-white">
          {plansSection.backgroundVideo ? (
            <>
              {" "}
              <div className="video relative ">
                <div
                  className={`relative mt-20 md:mt-10 z-20 bg-white ${
                    plansSection.backgroundVideo && "mask"
                  } border border-white`}
                >
                  <div className=" flex justify-center items-center">
                    <p className="gothanBold text-center  text-2xl md:text-2xl ">
                      {plansSection.title && plansSection.title}
                    </p>
                  </div>
                  <div className="energy gothanBlack  w-full flex justify-center items-center mt-2 md:mt-4">
                    <h2 className="text-6xl  md:text-8xl leading-lHeightM text-center  md:w-6/12 md:leading-lHeightD  gothanBlack ">
                      {plansSection.subtitle && plansSection.subtitle}
                    </h2>
                  </div>
                </div>
                <video
                  className="absolute border md:border-white h-[425px] md:h-[800px]  z-10 videSlider -top-[0px] md:-top-[0px] object-cover "
                  autoPlay={true}
                  loop={true}
                  controls={false}
                  playsInline
                  muted
                >
                  <source src={plansSection.backgroundVideo.asset.url} />
                </video>
              </div>
              <div className="w-full flex justify-center items-center weAreworking mt-6">
                <p className="text-sm gothanNarrowBook px-4 leading-7 md:text-lg md:w-6/12 text-center font-light">
                  {plansSection.description && plansSection.description}
                </p>
              </div>
              <div className="flex flex-col justify-center items-center mt-6 px-5 md:px-0">
                <div className=" flex justify-center items-center ">
                  <p className="gothanBold text-center  text-2xl md:text-2xl ">
                    Why Invest in Oil?
                  </p>
                </div>
                <ul className="w-full md:w-6/12  mt-6">
                  {reasonsToInvestInOil.map((reason, index) => (
                    <li
                      key={index + reason.title}
                      className="  flex justify-start items-start flex-col mt-6"
                    >
                      <h3 className="gothanBold text-left flex items-center  text-lg md:text-lg ">
                        <span className="text-sm">{index + 1}</span>
                        <span className=" ml-2"> {reason.title}</span>
                      </h3>
                      <p className="mt-2 text-sm text-gray-600">
                        {reason.description}
                      </p>
                    </li>
                  ))}
                </ul>
              </div>
            </>
          ) : (
            <DemoPlansSection />
          )}
        </div>

        <div className="plans w-full flex flex-col md:flex-row justify-center items-center mt-16 px-8">
          {plans.length !== 0 ? (
            <>
              {plans.map((plan, index) => (
                <div
                  key={plan.title}
                  className={`planCard md:mx-8 rounded-xl my-5 md:my-2  w-11/12   shadow-xl`}
                >
                  <div className="">
                    {plan.bestSeller ? (
                      <div className="ribbonContainer gothanBold text-">
                        {" "}
                        <div className="bestSeller text-sm planFeatured ">
                          Top Choice
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                    <>
                      <div className="w-full py-6 md:py-8 px-8  bg-[#0b2d71] text-white text-center rounded-t-xl  flex flex-col justify-center items-center">
                        <div className="w-full  gothanBold text-sm flex justify-center items-center">
                          {plan.title}
                        </div>
                        <div className="w-full  gothanNarrowBook leading-relaxed text-xs flex mt-2 justify-center items-center">
                          {plan.description}
                        </div>
                      </div>
                      <div className="w-full py-6 gothanNarrowBook md:py-8 px-4 flex flex-col justify-center items-center">
                        <div className="w-full my-2  text-xs flex justify-start items-center">
                          <span className="mx-2">
                            <GiCheckMark />
                          </span>
                          <span className="gothanNarrowBook text-black">
                            {" "}
                            {plan.returns}
                          </span>
                        </div>
                        <div className="w-full my-2  text-xs flex justify-start items-center">
                          <span className="mx-2">
                            <GiCheckMark />
                          </span>
                          <span className=" text-black text-[12px]">
                            {" "}
                            {plan.minInvestment}
                          </span>
                        </div>{" "}
                        <div className="w-full my-2  text-xs flex justify-start items-center">
                          <span className="mx-2">
                            <GiCheckMark />
                          </span>
                          <span className=" text-black text-[12px]">
                            {plan.maxInvestment}
                          </span>
                        </div>{" "}
                        <div className="w-full my-2  text-xs flex justify-start items-center">
                          <span className="mx-2">
                            <GiCheckMark />
                          </span>
                          <span className=" text-black text-[12px]">
                            {" "}
                            {plan.duration}
                          </span>
                        </div>{" "}
                        <div className="w-full my-2  text-xs flex justify-start items-center">
                          <span className="mx-2">
                            <GiCheckMark />
                          </span>
                          <span className=" text-black text-[12px]">
                            {" "}
                            {`Daily profit`}
                          </span>
                        </div>
                        <div className="w-full my-6  text-xs flex flex-col justify-center items-center">
                          <a
                            href={plan.link}
                            className={`${
                              plan.bestSeller && "bg-[#0b2d71] text-white"
                            } border-[#0b2d71] px-8 py-2 border rounded-md font-bold transition-all duration-300 hover:bg-[#0b2d71] hover:text-white`}
                          >
                            Get started
                          </a>
                          <div className="flex text-black text-[12px] my-4 justify-center items-end">
                            <span className="text-lg text-[#0b2d71]">
                              {" "}
                              <AiFillSafetyCertificate />
                            </span>
                            <span className="px-1"> {plan.guarantee}</span>
                          </div>
                        </div>
                      </div>
                    </>
                  </div>
                </div>
              ))}
            </>
          ) : (
            <>
              {" "}
              <MoonLoader />
            </>
          )}
        </div>
        <div className="flex flex-col justify-center items-center mt-12 px-5 md:px-0 w-full relative">
          <div
            style={{ fontSize: "170px" }}
            className="flex justify-center items-center absolute z-10 opacity-10 "
          >
            <BsShieldLock />
          </div>
          <div className=" flex justify-center items-center  ">
            <p className="gothanBold text-center  text-3xl md:text-3xl ">
              Total Profit Protection
            </p>
          </div>
          <div className="w-full flex justify-center items-center weAreworking mt-6">
            <div className=" text-gray-800 gothanNarrowBook px-4 leading-7 md:text-lg md:w-6/12 text-left font-light">
              <p className="text-sm">
                Each plan comes with a Total Profit Protection guarantee,
                ensuring that your investments are secure and profitable.
                Partner with CoralcoastOil and choose the investment plan that
                aligns with your financial goals.
              </p>
              <br />
              <p className="text-sm">
                Whether you are looking for short-term gains or long-term
                growth, our tailored plans provide the flexibility and returns
                to suit your needs.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
const DemoPlansSection = () => {
  return (
    <>
      {" "}
      <div className="video relative ">
        <div
          className={`relative mt-20 md:mt-10 z-20 bg-white  border border-white`}
        >
          <div className=" flex justify-center items-center">
            <p className="gothanBold text-center  text-2xl md:text-2xl ">
              Partner with coralcoastoil
            </p>
          </div>
          <div className="energy gothanBlack  w-full flex justify-center items-center mt-2 md:mt-4">
            <h2 className="text-6xl  md:text-8xl leading-lHeightM text-center  md:w-6/12 md:leading-lHeightD  gothanBlack ">
              investment options for every goal
            </h2>
          </div>
        </div>
      </div>{" "}
      <div className="w-full flex justify-center items-center weAreworking mt-6">
        <p className="text-sm gothanNarrowBook px-4 leading-7 md:text-lg md:w-6/12 text-center font-light">
          At CoralcoastOil, we offer personalized financial strategies to help
          you achieve your goals. Let our experienced team guide you to secure
          and grow your wealth. Your financial success starts here.
        </p>
      </div>
    </>
  );
};
export default Investment;
