import React from "react";
import "./Whatwedo.css";

import { MdOutlineArrowForwardIos } from "react-icons/md";
import { gridImages } from "../../seed/seed";
import Lowercarbon from "./Lowercarbon";
const Whatwedo = () => {
  return (
    <section className="pt-20 relative flex w-full justify-center items-center flex-col darkBlueTwo">
      <div className=" w-full relative h-full z-0 flex flex-col justify-start ">
        <div className="whatwedoTextHead h-[50%] top-0  bg-white">
          {" "}
          <div className=" flex justify-center items-center">
            <p className="gothanBold  text-xl md:text-2xl darkBlueTwo">
              What we do{" "}
            </p>
          </div>
          <div className="energy gothanBlack  w-full flex justify-center items-center mt-2 md:mt-4">
            <h2 className="text-6xl md:text-8xl leading-lHeightM text-center  md:w-5/12 md:leading-lHeightD  gothanBlack font-">
              energy that fuels the world
            </h2>
          </div>
          <div className="w-full flex justify-center items-center weAreworking">
            <p className="text-lg gothanNarrowBook px-4 leading-7 md:text-xl md:w-4/12 text-center font-light">
              We’re working to deliver the energy needed today. To lower the
              carbon intensity of our operations. And to build the lower carbon
              energy system of tomorrow.
            </p>
          </div>{" "}
          <div className="ctaBtn mt-8 w-full flex justify-center items-center">
            <a
              href="https://secured.coralcoastoil.com/see-our-solutions"
              className=" flex justify-evenly  font-bold border-2 border-[#0b2d71] rounded-lg hover:bg-[#0b2d71] transition-all duration-500 px-10 items-center hover:text-white text-black py-3 "
            >
              <span className="mr-4">
                {" "}
                <MdOutlineArrowForwardIos />
              </span>

              <span className="gothanBold"> see our solutions</span>
            </a>
          </div>
        </div>
        <div className="lowerHalf bg-white relative z-10">
          <div className="gridContainer flex-col flex w-full justify-center items-center px-4 py-2">
            {" "}
            <div className="randomGrid gallerry w-full md:w-6/12 h-full  mt-10 md:mt-12">
              {gridImages.map((image, index) => (
                <div
                  style={{
                    backgroundImage: `url(${image})`,
                  }}
                  key={index}
                  className="gallerryMedia bg-gray-200 shadow-lg"
                />
              ))}
            </div>
          </div>
        </div>
      </div>

      <Lowercarbon />
    </section>
  );
};

export default Whatwedo;
