import React, { useEffect, useState } from "react";
import { DesktopFooterItems, MobileFooterItems } from "../Navbar/Nav";
import { client } from "../../client";
import { motion, useTransform } from "framer-motion";

const Footer = ({ logos, footerMenus, scrollYProgress }) => {
  return (
    <motion.footer className="w-full h-[full] z-20 relative  md:px-40 py-10 bg-[#0b2d71] mt-20 flex flex-col justify-center items-center">
      <DesktopFooterItems footerMenus={footerMenus} logos={logos} />
      <MobileFooterItems footerMenus={footerMenus} logos={logos} />
    </motion.footer>
  );
};

export default Footer;
