import React from "react";
import { Nav } from "../Navbar/Nav";
import Slider from "../Slider/Slider";
import { ToastContainer } from "react-toastify";
import "./Header.css";
const Header = ({
  sidebarMobileActive,
  setSidebarMobileActive,
  logos,
  sidebarDesktopActive,
  setSidebarDesktopActive,
  headerMenus,
  headerChildMenu,
  setHeaderChildMenu,
  setSearchActive,
  searchActive,
}) => {
  return (
    <header className="relative w-full h-full ">
      <ToastContainer />
      <Nav
        sidebarMobileActive={sidebarMobileActive}
        setSidebarMobileActive={setSidebarMobileActive}
        logos={logos}
        headerMenus={headerMenus}
        sidebarDesktopActive={sidebarDesktopActive}
        setSidebarDesktopActive={setSidebarDesktopActive}
        headerChildMenu={headerChildMenu}
        setHeaderChildMenu={setHeaderChildMenu}
        searchActive={searchActive}
        setSearchActive={setSearchActive}
      />
      <Slider />
    </header>
  );
};

export default Header;
