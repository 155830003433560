import React, { useEffect, useState } from "react";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import Table from "./Table";
import axios from "axios";
import { GET_TABLE_DATA } from "../../query";
const Activity = () => {
  const [loading, setLoading] = useState(true);
  const [timerDuration, setTimerDuration] = useState(0);
  const [dataTable, setDataTable] = useState([]);
  useEffect(() => {
    fetchData();
  }, []);
  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await axios.get(GET_TABLE_DATA);
      // console.log(response.data);
      setDataTable(response.data);
      if (response.data.length) {
        setTimeout(() => {
          setTimerDuration(300);
          setLoading(false);
        }, 2000);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };
  return (
    <div className="text-black w-full mt-20 flex justify-center items-center flex-col">
      <div className=" flex justify-center items-center">
        <p className="gothanBold  text-2xl md:text-4xl darkBlueTwo">
          Investors Activities
        </p>
      </div>

      <div className="countdownTimer mt-3 flex flex-col justify-center items-center w-11/12 md:w-6/12">
        <Table
          showBtn="MORE"
          loading={loading}
          loadTables={fetchData}
          data={dataTable}
          timerDuration={timerDuration}
        />
      </div>
    </div>
  );
};

export default Activity;
