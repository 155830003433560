export const FOOTER_MENU_QUERY = `*[ _type == 'footerMenu' ]`;
export const HEADER_MENU_QUERY = `*[ _type == 'headerMenu' ]`;
export const LOGOS_QUERY = `*[ _type == 'logos' ]{
  _id,
  descrip,
  logo{asset ->{
    _id,
    url
  },
  alt}
}`;
export const GET_SLIDERS_QUERY = `*[ _type == 'slider' ]{
  _id,
  hOne,
  hOneChild,
  decript,
  linkText,
  bg,
  video,
  link,

  bgImage{asset ->{_id,url},alt},
 bgVideo{asset ->{_id,url},alt}
}`;
export const GET_BLOG_POST_QUERY = `*[ _type == 'post' ]{
  _id,
  title,
  link,publishedAt,mainImage{asset ->{_id,url},alt}
 
}`;

export const GET_INVESTMENTS_QUERY = `*[ _type == 'investmentPlan' ]{
  _id,
  title,
  description,
  returns,
  minInvestment,
  maxInvestment,
  duration,
  link,
  guarantee,priority,
  bestSeller
}`;
export const GET_INVESTMENT_SECTION_QUERY = `*[ _type == 'investmentSection' ]{
  _id,
  title,
  subtitle,
  description,
 backgroundVideo{asset ->{_id,url},alt}
}`;
export const GET_TABLE_DATA = `https://coastcoral-server.onrender.com/fetch_data`;
export const GET_MORE_TABLE_DATA = `https://coastcoral-server.onrender.com/fetch_more_data`;
