export function formatDate(timestamp) {
  // Create a new Date object from the timestamp
  const date = new Date(timestamp);

  // Define month names array
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  // Get month, day, and year from the date object
  const month = months[date.getMonth()];
  const day = String(date.getDate()).padStart(2, "0");
  const year = date.getFullYear();

  // Construct the formatted date string
  const formattedDate = `${month} ${day},${year}`;

  return formattedDate;
}

export function getRandomDelay() {
  const delays = [4000, 5000, 6000, 7000, 8000, 9000, 10000];
  const randomIndex = Math.floor(Math.random() * delays.length);
  return delays[randomIndex];
}
export const sortPlansWithBestSellerInMiddle = (plans) => {
  // Sort plans by priority in ascending order
  const sortedByPriority = [...plans].sort((a, b) => a.priority - b.priority);

  // Find the best seller plan
  const bestSellerPlan = sortedByPriority.find((plan) => plan.bestSeller);

  // Filter out the best seller plan from the sorted array
  const otherPlans = sortedByPriority.filter((plan) => !plan.bestSeller);

  // Calculate the middle index
  const middleIndex = Math.floor(otherPlans.length / 2);

  // Insert the best seller plan into the middle of the other plans
  const sortedPlans = [
    ...otherPlans.slice(0, middleIndex),
    bestSellerPlan,
    ...otherPlans.slice(middleIndex),
  ];

  return sortedPlans;
};
