import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./index.css";
import "./fonts.css";

import Home from "./components/Pages/Home";
import Activity from "./components/Activity/Activity";
import MoreActivity from "./components/Pages/MoreActivity";

const App = () => {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route index element={<Home />} />
          <Route path="/" element={<Home />} />
          <Route path="/more_activity" element={<MoreActivity />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
};
export default App;
