import React, { useEffect, useLayoutEffect, useState } from "react";
import "./Whoreare.css";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useRef } from "react";

import { MdOutlineArrowForwardIos } from "react-icons/md";
import { motion, useScroll, useTransform } from "framer-motion";
const Whoweare = () => {
  const scollMedia1 =
    "https://cdn.sanity.io/images/dl09lxj1/production/0ba7e8ce9c7974f030fce233f195eae5b60692de-526x297.png";
  const scrollMedia2 =
    "https://cdn.sanity.io/images/dl09lxj1/production/d67c7fd95dcca3132d14835e99225ae732b2ca2d-372x210.png";
  const scrollMedia3 =
    "https://cdn.sanity.io/images/dl09lxj1/production/4a74379a4b027e45c92b639e8de3c933631605d2-421x237.png";
  const scrollMedia4 =
    "https://cdn.sanity.io/images/dl09lxj1/production/2c0a7797ddd5c3bc8fa61322e4aabab98b2760e1-996x560.jpg";
  const sectionRef = useRef(null);
  const mediaRef = useRef(null);
  gsap.registerPlugin(useGSAP, ScrollTrigger);
  const container = useRef();
  const { scrollYProgress } = useScroll({
    target: container,
    offset: ["start start", "end end"],
  });
  useLayoutEffect(() => {
    const section = sectionRef.current;
    const textLines = section.querySelectorAll(".animateTextScroll");

    textLines.forEach((line, index) => {
      const [x, xEnd] =
        index % 2
          ? [window.innerWidth, (line.scrollWidth - section.offsetWidth) * -1]
          : [line.scrollWidth * -1, 0];

      gsap.fromTo(
        line,
        { x },
        {
          x: xEnd,
          scrollTrigger: {
            trigger: section,
            scrub: 5,

            start: "top 100%",
            end: "top center",
          },
        }
      );
    });
  }, []);

  // useLayoutEffect(() => {
  //   gsap.registerPlugin(ScrollTrigger);

  //   gsap
  //     .timeline({
  //       scrollTrigger: {
  //         trigger: ".imageContainer div",
  //         scrub: 0.3,
  //         start: "top 40%",
  //         end: "top 20%",
  //       },
  //     })
  //     .to(".imageContainer div", {
  //       opacity: 1,
  //       duration: 1,
  //       ease: "none",
  //       stagger: 1,
  //     });

  //   return () => {
  //     // Clear console on unmount
  //     console.clear();
  //   };
  // }, []);
  useLayoutEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    gsap
      .timeline({
        scrollTrigger: {
          trigger: "#descript .changeOpacity",
          scrub: 5,
          start: "top 80%",
          end: "top 20%",
        },
      })
      .to("#descript .changeOpacity", {
        opacity: 1,
        duration: 1,

        ease: "none",
        stagger: 1,
      });

    return () => {
      // Clear console on unmount
      console.clear();
    };
  }, []);
  const scale = useTransform(scrollYProgress, [0, 2], [1, 0.1]);

  return (
    <section className="mainContainer w-full h-full relative  flex justify-center items-center flex-col ">
      <div className=" relative flex justify-center items-center flex-col w-full  whoweareDarkBlue">
        <div ref={container} className="w-full relative h-full">
          <motion.div
            style={{ scale }}
            className="h1Div mt-20 mb-32 sticky   top-0 h-[50%] z-10  flex flex-col"
          >
            {" "}
            <h1
              className="text-white text-lg md:text-xl font-bold w-full
            text-center relative z-10 gothanBold"
            >
              {" "}
              Who we are
            </h1>{" "}
            <ul
              ref={sectionRef}
              class="animateTextScrollParent md:mt-20 mt-10  overflow-hidden  z-10 text-6xl md:text-8xl dm-serif-display-regular"
            >
              <li class="animateTextScroll lightBlue gothanBlack leading-lHeightM md:leading-lHeightD ">
                critital thinkers
              </li>
              <li class="animateTextScroll mediumBlue leading-lHeightM md:leading-lHeightD gothanBlack">
                collaborators
              </li>
              <li class="animateTextScroll daarkBlue leading-lHeightM md:leading-lHeightD gothanBlack">
                innovators
              </li>
            </ul>
          </motion.div>
          <div
            ref={mediaRef}
            style={{ opacity: 1 }}
            className=" relative  py-20   z-20 panel flex hscroll flex-col items-end w-full "
          >
            <div className="firstImage transition-all duration-300   scrollMedia  pr-6 mb-20 md:pr-80 w-full flex justify-end items-end">
              <img
                className=" rounded-xl w-7/12 md:w-3/12 "
                src={scollMedia1}
                alt="scollMedia1"
              />
            </div>
            <div className="secondImage scrollMedia  pl-6 mb-16 md:mb-20  md:pl-96 w-full flex justify-start items-start">
              <img
                className=" rounded-xl w-7/12 md:w-3/12 "
                src={scrollMedia2}
                alt="scrollMedia2"
              />
            </div>
            <div className="thirdImage scrollMedia transition-all duration-1000  pr-10 mb-20 md:mb-40 md:pr-28 w-full flex justify-end items-end">
              <img
                className=" rounded-xl w-10/12 md:w-2/12 "
                src={scrollMedia4}
                alt="scollMedia1"
              />
            </div>
            <div className="fourthImage scrollMedia transition-all duration-1000   mb-40 w-full flex justify-center items-center">
              <img
                className=" rounded-xl w-7/12 md:w-3/12"
                src={scrollMedia3}
                alt="scollMedia1"
              />
            </div>
          </div>
        </div>

        <div
          id="descript"
          className="weBelive w-full px-4  md:px-12 text-white flex justify-center items-center"
        >
          <p className="text-white gothanNarrowBook text-center text-dcriptFontM description md:text-dcriptFontD font-light md:leading-dcriptD leading-dcriptM text-2xl md:text-6xl  md:w-6/12 ">
            We believe that humanity can solve any challenge.
            <span className="changeOpacity">
              That our greatest resource is our people. And that responsibility,
              trust and integrity will help us drive a prosperous future.
            </span>
          </p>
        </div>
        <div className="ctaBtn mt-16 w-full flex justify-center items-center">
          <a
            href="https://secured.coralcoastoil.com/who-we-are/"
            className=" flex justify-evenly  font-bold border-2 border-[#0066B2] rounded-lg hover:bg-[#0066B2] transition-all duration-500 px-10 items-center text-white hover:text-black py-3 "
          >
            <span className="mr-4">
              {" "}
              <MdOutlineArrowForwardIos />
            </span>

            <span className="gothanBold">discover who we are</span>
          </a>
        </div>

        <div class="spacer"></div>
      </div>
    </section>
  );
};

export default Whoweare;
